<template>
    <v-container>
      <v-card
        class="mx-auto mb20"
        max-width="344"
        outlined
        v-for="category in categories"
        :key="category._id"
        elevation="5"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <div class="overline mb-4 deep-purple--text text--lighten-2">
              {{category.title}}
            </div>
            <v-list-item-subtitle>{{category.description}}</v-list-item-subtitle>
          </v-list-item-content>

          <v-card rounded="xl" elevation="0">
            <v-img
              max-width="80"
              :src="category.image"
            ></v-img>
          </v-card>
        </v-list-item>

        <v-divider class="mt-4 mb-4 mx-4"></v-divider>

        <v-list-item
          v-for="service in getServicesOfCategory(category)"
          :key="service._id"
        >
          <v-list-item-content>
            <v-list-item-title v-text="service.title"></v-list-item-title>
            <v-list-item-subtitle>{{ service.subtitle }}</v-list-item-subtitle>
            <v-list-item-subtitle>Цена: {{ service.price }} руб</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-container>
</template>

<script>
  export default {
    name: "PriceList",
    data: () => ({
      categories: [],
      services: [],
      select: "",
    }),

    methods: {
      async loadCategoryList() {
        const response = await fetch(`${this.$store.state.server}/category/load-category-list-for-client`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({owner: this.$store.getters.ownerId})
        })

        const json = await response.json()
        if (json.message) {
          this.$store.commit("setServerMessage", json.message)
        }
        if (response.status === 200) {
          this.categories = json.categories
        }
      },

      async loadServiceList() {
        const response = await fetch(`${this.$store.state.server}/service/load-service-list-for-client`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({owner: this.$store.getters.ownerId})
        })

        const json = await response.json()
        if (json.message) {
          this.$store.commit("setServerMessage", json.message)
        }
        this.services = json.services
      },

      getServicesOfCategory(category) {
        if (category) {
          return this.services.filter(s => s.category === category._id)
        }
        return []
      }
    },

    async mounted() {
      await this.loadServiceList()
      await this.loadCategoryList()
      if (this.$route.query.id) {
        this.select = this.categories.find(c => c._id === this.$route.query.id).title
      } else {
        this.select = this.categories[0].title
      }
    },

    watch: {
      item: function (i) {
        if (i !== undefined) {
          this.service = this.getServicesOfCategory[i]
          this.title = this.getServicesOfCategory[i].title
          this.price = this.getServicesOfCategory[i].price
        } else {
          this.service = ""
          this.title = ""
          this.price = ""
        }
      }
    }
  }
</script>

<style scoped>

</style>
